import React, { FC } from 'react';
import sty from './Modal.module.css';
interface Props {
  buttonTitle?: string;
  noBorderRadiusOnTablet?: boolean;
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: FC<Props> = ({
  children,
  noBorderRadiusOnTablet,
  onClose,
  show
}) => {
  const borderRadius = noBorderRadiusOnTablet ? sty.modal_square : '';
  const close = show ? '' : sty.modal_close;
  const modalClassName = `${sty.modal} ${close}`;

  const closeModal = (e: React.MouseEvent<EventTarget>) => {
    const el = e.target as HTMLElement;
    if (el.classList.value.includes(modalClassName)) {
      onClose();
    }
  };

  return (
    <div className={`${modalClassName} ${borderRadius}`} onClick={closeModal}>
      <div>{children}</div>
    </div>
  );
};

export default Modal;
