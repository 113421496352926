'use client';
import { useSession } from 'next-auth/react';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react';
import Modal from 'components/Modal';
import Link from 'next/link';
import sty from './styles.module.css';
import { Header } from 'components/Modal';
import { SiteContext } from 'context/SiteContext';
import IconSave from 'components/Icon/IconSave';

interface Props {
  title: string;
  slug?: string;
  contentArea: string;
  resourceType: string;
  id: string;
  album?: string;
  artist?: string;
  duration?: string;
  art_url?: string;
  isFavorite?: boolean;
  transparent?: boolean;
  iconClassName?: string;
  hiddenIcon?: boolean;
  programId?: string;
  location?: string;
  venue?: string;
  occurrences?: {
    startTime: string;
    endTime: string;
  }[];
  iconHeight?: string;
  iconWidth?: string;
  additionalText?: string;
  buttonHeight?: number;
}

const AddFavorite: FunctionComponent<Props> = ({
  title,
  contentArea,
  resourceType,
  slug,
  id,
  album,
  artist,
  duration,
  art_url,
  isFavorite,
  transparent,
  iconClassName,
  hiddenIcon,
  programId,
  location,
  venue,
  occurrences,
  iconHeight,
  iconWidth,
  additionalText,
  buttonHeight
}) => {
  const sessionData = useSession();
  const status = sessionData.status;
  const [isOn, setIsOn] = useState(isFavorite);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showPlaylistModal, setShowPlaylistModal] = useState<boolean>(false);
  const context = useContext(SiteContext);

  useEffect(() => {
    setIsOn(isFavorite);
  }, [isFavorite]);

  const handleClick = async () => {
    if (status === 'authenticated') {
      const method = isOn ? 'DELETE' : 'POST';
      const responsePromise = fetch('/api/data/favorite', {
        method,
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title,
          contentArea,
          resourceType,
          slug,
          cmsId: id,
          album,
          artist,
          duration,
          art_url,
          programId,
          location,
          venue,
          occurrences
        })
      });
      if (resourceType === 'song') {
        if (
          !isOn &&
          (!context.favoriteSongs || context.favoriteSongs.size === 0)
        ) {
          setShowPlaylistModal(true);
        }
        setIsOn(!isOn);
        await responsePromise;
        context.fetchFavoriteSongs();
      }
    }
    if (status !== 'authenticated') {
      setShowLoginModal(true);
    }
    if (
      resourceType === 'story' ||
      resourceType === 'event' ||
      resourceType === 'episode'
    ) {
      setIsOn(!isOn);
    }
  };

  const loginBtnLinks = [
    {
      text: 'Log in',
      href: '/for-you?auth=signin',
      className: 'button button-primary'
    },
    {
      text: 'Create a free Account',
      href: '/for-you?auth=new-user',
      className: 'button button-secondary'
    }
  ];

  return (
    <>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowLoginModal(false)}
        show={showLoginModal}
      >
        <div className={sty.modal}>
          <Header
            title="Like this?"
            className={sty.modal_header}
            onClose={() => setShowLoginModal(false)}
          />
          <p>
            Log in to share your opinion with The Current and add it to your
            profile.
          </p>
          <div className={sty.modal_buttons}>
            {loginBtnLinks.map((link, idx) => {
              return (
                <Link href={link.href} key={idx} prefetch={false} passHref>
                  <button
                    className={`${link.className} btn`}
                    type="button"
                    onClick={() => setShowLoginModal(false)}
                  >
                    {link.text}
                  </button>
                </Link>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        noBorderRadiusOnTablet
        onClose={() => setShowPlaylistModal(false)}
        show={showPlaylistModal}
      >
        <div className={sty.modal}>
          <Header
            title="Like this?"
            className={sty.modal_header}
            onClose={() => setShowPlaylistModal(false)}
          />
          <p>
            Thanks for liking this song! We have added it to a personal playlist
            for you.
          </p>
          <div className={sty.modal_buttons}>
            <Link href="/for-you/playlist" prefetch={false} passHref>
              <button
                className="btn"
                type="button"
                onClick={() => setShowPlaylistModal(false)}
              >
                Show me!
              </button>
            </Link>
          </div>
        </div>
      </Modal>
      <IconSave
        className={iconClassName}
        on={isOn}
        transparent={transparent}
        onClick={handleClick}
        height={buttonHeight ?? 25}
        iconHeight={iconHeight}
        iconWidth={iconWidth}
        additionalText={additionalText}
        resourceType={resourceType}
      />
    </>
  );
};

export default AddFavorite;
