import { FC } from 'react';
import sty from './IconReview.module.css';
import IconRockOn from './IconRockOn';

interface Props {
  className?: string;
  colorOn?: string;
  color?: string;
  height?: number;
  isDownVote?: boolean;
  on?: boolean;
  transparent?: boolean;
  iconHeight?: string;
  iconWidth?: string;
  additionalText?: string;
  onClick?: () => void;
  resourceType?: string;
}

const IconSave: FC<Props> = ({
  className = '',
  colorOn = 'var(--color-green)',
  height,
  isDownVote,
  on,
  transparent,
  iconHeight,
  iconWidth,
  additionalText = '',
  onClick,
  resourceType = 'song'
}) => {
  const statusColorBase = transparent ? 'none' : 'var(--color-white)';
  const text = on ? 'Saved' : 'Save';
  const statusColor = on ? colorOn : statusColorBase;

  const btnStyles = {
    height: height,
    display: 'flex',
    marginRight: '.5rem'
  };
  const label = isDownVote ? `Dislike ${resourceType}` : `Like ${resourceType}`;

  return (
    <button
      aria-label={label}
      className={`${className} ${sty.reviewButton}`}
      style={{ ...btnStyles }}
      title={label}
      onClick={onClick}
    >
      <>
        <IconRockOn
          className={className}
          fill={statusColor}
          height={iconHeight}
          width={iconWidth}
        />
      </>
      &nbsp;
      <span className="playlist-save">
        {text} {(on == false || on == undefined) && additionalText}
      </span>
    </button>
  );
};

export default IconSave;
