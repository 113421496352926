import { FC } from 'react';
import { JsxElement } from 'typescript';
import IconCloseModal from 'components/Icon/IconCloseModal';
import sty from './Modal.module.css';

interface Props {
  className?: string;
  closeIcon?: React.ReactNode;
  onClose?: () => void;
  textAboveTitle?: string;
  textAboveTitleClassName?: string;
  textBelowTitle?: string;
  textBelowTitleClassName?: string;
  title?: string;
  titleClassName?: string;
  children?: React.ReactNode;
}

const Header: FC<Props> = ({
  children,
  className = '',
  closeIcon,
  onClose,
  textAboveTitle,
  textAboveTitleClassName = '',
  textBelowTitle,
  textBelowTitleClassName = '',
  title,
  titleClassName = ''
}) => {
  const iconClose = closeIcon || <IconCloseModal />;

  return (
    <header className={`${sty.modal_header} ${className}`}>
      <div>
        {textAboveTitle && (
          <div className={textAboveTitleClassName}>{textAboveTitle}</div>
        )}

        {title && <h3 className={titleClassName}>{title}</h3>}

        {textBelowTitle && (
          <div className={textBelowTitleClassName}>{textBelowTitle}</div>
        )}

        {children}
      </div>

      {onClose && (
        <button onClick={onClose} className={sty.btn_close} type="button">
          {iconClose}
        </button>
      )}
    </header>
  );
};

export default Header;
